.purple-header{
  background: #501687 !important;
}

.btn-primary {
  background: linear-gradient(90deg,#d62891 0,#f6c120 100%) no-repeat padding-box;
}

.white {
  color: white !important;
}

.login-avatar{
  margin: 10px;
  background: linear-gradient(90deg,#d62891 0,#f6c120 100%) no-repeat padding-box;
}

.login-form {
  margin: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}